import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function XmasBackground({ children }) {
  return (
    <>
      <div className="relative h-full w-full">
        <XmasHeader />
        <WhitePaperBackground />
        <XmasTopLeftMotive />
        <XmasBottomLeftMotive />
        <XmasBottomRightMotive />

        {children}
      </div>
    </>
  );
}

function XmasHeader() {
  return (
    <header className="flex justify-center py-0 sm:py-0 px-5 font-normal landscape:h-[20vh] landscape:lg:h-[15vh] h-[15vh] md:h-[10vh] max-h-32">
      <StaticImage
        src="../img/xmas-aa-logo.png"
        loading="eager"
        className=""
        objectFit="contain"
        alt=""
        placeholder="none"
      />
    </header>
  );
}

function WhitePaperBackground() {
  return (
    <>
      <div className="absolute inset-0 w-full h-full z-[-100]">
        <StaticImage
          src="../img/xmas-white-paper.jpg"
          loading="eager"
          className="h-full"
          objectFit="cover"
          alt=""
          placeholder="none"
        />
      </div>
    </>
  );
}

function XmasTopLeftMotive() {
  return (
    <div className="absolute top-0 left-0 w-1/6 md:w-1/12 lg:w-1/6 max-w-xs z-[-10]">
      <StaticImage
        src="../img/xmas-hanging-stars-cropped.png"
        loading="eager"
        className=""
        objectFit="contain"
        alt=""
        placeholder="none"
      />
    </div>
  );
}

function XmasBottomLeftMotive() {
  return (
    <div className="absolute bottom-0 left-0 w-1/2 z-[-10]">
      <StaticImage
        src="../img/xmas-bottom-left.png"
        loading="eager"
        className="h-full"
        objectFit="contain"
        alt=""
        placeholder="none"
      />
    </div>
  );
}

function XmasBottomRightMotive() {
  return (
    <div className="absolute bottom-0 right-0 flex justify-end w-1/4 z-[-10]">
      <StaticImage
        src="../img/xmas-bottom-right.png"
        loading="eager"
        className="h-full"
        objectFit="contain"
        alt=""
        placeholder="none"
      />
    </div>
  );
}
