import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import Section from "../components/Section";
import Video from "../components/Video";
import FunnelForm from "../components/FunnelForm";
import XmasBackground from "../components/XmasBackground";

const VideoOptInPageTemplate = ({ data }) => {
  data.heroObject.video.format = "Querformat";

  return (
    <Layout isWithHeader={false} seoObject={data.seoObject}>
      <XmasBackground>
        <Section classNameInnerContainer="flex flex-col space-y-10">
          <div className="text-center">
            <RenderMarkdown
              markdownContent={data.heroObject.title}
              isMarkdown={false}
              // class for spacing between lines: leading-none, leading-tight, leading-snug, leading-normal
              className="font-medium text-4xl sm:text-6xl text-brand-gold leading-tight mt-0"
              whitespace="normal"
            />
            <RenderMarkdown
              markdownContent={data.heroObject.subtitle}
              isMarkdown={false}
              className="text-2xl sm:text-4xl text-brand-green mt-5 sm:mt-14"
              whitespace="normal"
            />
          </div>
          <Video videoObject={data.heroObject.video} loading="eager" />
          <div className="mx-auto w-full max-w-xl lg:max-w-5xl">
            <FunnelForm
              classNameForm=""
              classNameButton="max-w-xs mx-auto"
              data={data.signUpObject}
            />
          </div>
        </Section>
      </XmasBackground>
    </Layout>
  );
};

const VideoOptInPage = ({ data }) => {
  return <VideoOptInPageTemplate data={data.markdownRemark.frontmatter} />;
};

export default VideoOptInPage;

export const pageQuery = graphql`
  query VideoOptInPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
        heroObject {
          ctaObject {
            linkText
            linkUrl
          }
          title
          subtitle
          video {
            cloudflareId
          }
        }
        signUpObject {
          title
          subtitle
          cta
          ctaSubtitle
          funnelId
          href
        }
      }
    }
  }
`;
